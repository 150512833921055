import React, { useState } from "react";
import "./deleteAlbum.css";

import axios from "axios";
import Modal from "@mui/material/Modal";
import CrossImg from "../../../assets/events/cross.svg";
import { TOKEN, dEV_API_URL } from "../../../API";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

import { fetchAlbumList, resetAlbumList } from "../Albums/AlbumListReducer";
import ClipLoader from "react-spinners/ClipLoader";

import { toast } from "react-toastify";

const DeleteAlbum = ({ open, onClose, selectedAlbumData, eventId }) => {
  const [postStatus, setPoststatus] = useState(false);
  const [isEventDeleted, setIsEventDeleted] = useState(false);

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { albumId, photosCount } = selectedAlbumData;


  const deleteAlbum = (albumId) => {
    setPoststatus(true);

    const data = {
      album_ids: [albumId], 
    };

    axios({
      method: "DELETE",
      url: `${dEV_API_URL}/events/albums/`,
      headers: { 
        "content-type": "application/json",
        Authorization:
        TOKEN,      },
      data,
    })
      .then((resp) => {
        dispatch(resetAlbumList());
        dispatch(fetchAlbumList({eventId: eventId, pageNumber: 1})); 
        setPoststatus(false);
        onClose();
        toast.success('Album Deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      })

      .catch((err) => {
        setPoststatus(false);
        toast.warning('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      })
      .finally(() => {});
  };

  return (
    <>
      <Modal
        className="delete_album_modal_main_container"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="delete_album_modal_card">
          <div className="delete_album_modal_card_header">
            <p>Delete Album</p>
            <img onClick={onClose} src={CrossImg} alt="icon" />
          </div>
          <p className="delete_album_modal_card_para">
            This album has {photosCount} photos. Are you sure you want to delete
            this album? This action will permanently remove all associated
            photos.
          </p>

          <div className="delete_album_modal_btns">
            <p className="delete_album_modal_cancel_btn" onClick={onClose}>
              <span>Cancel</span>
            </p>
            {postStatus === false ?
            <p
              className="delete_album_modal_delete_btn"
              onClick={() => deleteAlbum(albumId)}
            >
              <span>Delete</span>
            </p> : 
             <p
             className="delete_album_modal_delete_btn"
           >
             <span>
             <ClipLoader
                    color="white"
                    size={24}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
             </span>
           </p> }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAlbum;
