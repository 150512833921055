import React, { useState } from "react";
import "../DeleteEvent/deleteEvent.css";

import axios from "axios";
import Modal from "@mui/material/Modal";
import CrossImg from "../../../assets/events/cross.svg";
import { TOKEN, dEV_API_URL } from "../../../API";
import { useDispatch } from "react-redux";
import { fetchEventList, resetEventList } from "../EventListReducer";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";


const DeleteEvent = ({ open, onClose, selectedEventData }) => {
  const [postStatus, setPoststatus] = useState(false);

  const dispatch = useDispatch();
  const { eventId, albumCount, photoCount } = selectedEventData;

  const deleteEvent = (eventId) => {
    setPoststatus(true);

    const data = {
      event_ids: [eventId],
    };

    axios({
      method: "DELETE", 
      url: `${dEV_API_URL}/events/events/`,
      headers: {
        "content-type": "application/json",
        Authorization:
        TOKEN},
      data,
    })
      .then((resp) => {
        dispatch(resetEventList());
        dispatch(fetchEventList({ pageNumber: 1}));  
        setPoststatus(false);
        onClose(); 
        toast.success('Event Deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined, 
          theme: "dark",
          });
      })
      .catch((err) => {
        setPoststatus(false); 
        toast.warning('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      })
      .finally(() => {});
  };

  return (
    <>
      <Modal
        className="delete_modal_main_container"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="delete_modal_card">
          <div className="delete_modal_card_header">
            <p>Delete Event</p>
            <img onClick={onClose} src={CrossImg} alt="icon" />
          </div>
          <p className="delete_modal_card_para">
            This event has {albumCount} album and {photoCount} photos. <br />
            Are you sure you want to delete this event? This action will
            permanently remove all associated albums and photos.
          </p>

          <div className="delete_modal_btns">
            <p className="delete_modal_cancel_btn" onClick={onClose}>
              <span>Cancel</span>
            </p>
            {postStatus === false ? (
              <p
                className="delete_modal_delete_btn"
                onClick={() => deleteEvent(eventId)}
              >
                <span>Delete</span>
              </p>
            ) : (
              <p className="delete_modal_delete_btn" style={{cursor: "not-allowed"}}>
                <span>
                  <ClipLoader
                    color="white"
                    size={24}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </span>
              </p>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteEvent;
