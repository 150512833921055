import React, { useState, useEffect, useCallback, useRef } from "react";
import "../../features/ViewEventImages/viewEventImages.css";

import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchImages } from "./ViewEventImagesReducer";

import { Modal, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import CloseIcon from "../../assets/icons/close.svg";
import DownloadIcon from "../../assets/icons/download.svg";

const ViewEventImages = () => {
  const dispatch = useDispatch();
  const imageUrls = useSelector((state) => state.eventPhotos.imageUrls);
  const count = useSelector((state) => state.eventPhotos.count);
  const hasMore = useSelector((state) => state.eventPhotos.hasMore);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
 
  let queryParams = new URLSearchParams(window.location.search);
  let Id = queryParams.get("id");
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(() => {
    dispatch(fetchImages({ albumId: Id, pageNumber: imageUrls.length / 10 + 1 }));
  }, [dispatch,Id, imageUrls]);
 
  const openImage = (index) => { 
    setSelectedImageIndex(index);
    setOpenGalleryModal(true);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };
  const navigate = (direction) => {
    if (selectedImageIndex !== null) {
      const newIndex = selectedImageIndex + direction;
      if (newIndex >= 0 && newIndex < imageUrls.length) {
        setSelectedImageIndex(newIndex);
      }
    }
  };
  const downloadImage = async () => {
    try {
      setLoading(true);
      if (selectedImageIndex !== null) {
        const imageUrl = imageUrls[selectedImageIndex].image;
        const parts = imageUrl.split("/");
        const filenameWithQuery = parts[parts.length - 1];

        const filenameParts = filenameWithQuery.split("?");
        const filename = filenameParts[0];

        let imageName = filename;
        const response = await fetch(imageUrl, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = imageName;
        document.body.appendChild(link);
        link.click(); 
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Set loading state to false when download completes
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow key pressed
        navigate(-1);
      } else if (event.keyCode === 39) {
        // Right arrow key pressed
        navigate(1);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);


  return (
    <div>
      {/* <Header /> */}
      <div className="OuterViewPhotoContainer">
        {/* <SideBar /> */}
        <div className="innerViewPhotoContainer">
      
          <InfiniteScroll
            dataLength={imageUrls.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<p>Loading......</p>}
            endMessage={<p className="your_image_no_more">No more data to load.</p>} 
          >
            <ul className="imagesList" style={{ marginTop: "50px" }}>
              {imageUrls.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl.image}
                  alt={`Image_${index}`}
                  className="imageStyle"
                  onClick={() => openImage(index)}
                />
              ))}
            </ul>
          </InfiniteScroll>
          {selectedImageIndex !== null && (
            <Modal open={openGalleryModal} className="imageModal">
              {imageUrls && imageUrls[selectedImageIndex] && (
                <Box
                className="imageModal_box"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  background: "#0c0715",
                }}
              >
                <div className="viewEventImages_modal_inner_div">
                   <div className="viewEventImages_modal_buttons_div">
                    <div className="viewEventImages_modal_buttons_header">
                    {loading ? (
                        <CircularProgress size={24} /> 
                      ) : 
                      <img
                        onClick={downloadImage}
                        src={DownloadIcon}
                        alt="download"
                      />}

                      <img onClick={closeImage} src={CloseIcon} alt="close" />
                    </div>
                    <div>
                      <div className="viewEventImages_modal_buttons_arrows">
                        <div className="viewEventImages_modal_buttons_arrows_inner">
                          {selectedImageIndex > 0 && (
                            <div onClick={() => navigate(-1)}>
                              <ArrowBackIosNewIcon className="viewEventImages_modal_buttons_arrow_icons" />
                            </div>
                          )}
                        </div>
                        <div className="viewEventImages_modal_buttons_arrows_inner">
                          {selectedImageIndex < imageUrls.length - 1 && (
                            <div onClick={() => navigate(1)}>
                              <ArrowForwardIosIcon className="viewEventImages_modal_buttons_arrow_icons" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                
                      <img
                        src={imageUrls[selectedImageIndex].image}
                        alt={`Image_${selectedImageIndex}`}
                        className="modal-image"
                      />

                  
                </div>
                </Box>
              )}
            </Modal>
          )}
        </div>
      </div>

      {error && <p>Error: {error.message}</p>}
    </div>
  );
};

export default ViewEventImages;
