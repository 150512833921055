import React from "react";
import './Text.css'

function Text() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="containerText">
                <h1>GET YOUR PHOTOS <br/>
                <span> - IN SECONDS</span>
                </h1>
                <div>
                    <p>Our unique facial recognition technology eliminates that hassle and time it takes to search for photogprahs.
                    Simply upload your photo or a selfie, and sit back while Snapd finds and collates all your pictures in seconds!</p>
                </div>
            </div>
        </div>
    )
}
export default Text;  