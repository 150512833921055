import React, { useState, useEffect } from "react";
import "../EventSetting/index.css";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import ArrowImg from "../../assets/eventSetting/arrow.svg";
import Basic from "./Basic/Basic";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEventSettingPage } from "./eventSettingReducer";
import OrgInfo from "./OrgInfo/OrgInfo";
import Integration from "./Integration/Integration";
import { Link } from "react-router-dom";
import axios from "axios";
import BrandLogo from "./brandLogo/BrandLogo";
import BrandWatermark from "./brandWatermark/BrandWatermark";

const Index = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.eventSetting.eventSettingPage);


  // useEffect(() => {
  //   if (state) {  
  //     console.log(state);
  //   }
  // }, [state]);  


  return (
    <>
      <Header />
      <div className="event_setting_main_container">
        <SideBar />
        <div className="event_setting_main_div">
          <div className="event_setting_header_div">
            <Link style={{ textDecoration: "none" }} to="/events">
              <div style={{cursor: "pointer"}}>
                <img src={ArrowImg} alt="arrow" />
              </div>
            </Link>
            <p>Maruti Suzuki Arena Devils Circuit | Legends Cup 2023-24</p>
          </div>

          <div className="event_setting_subheader_div">
            <div className="event_setting_subheader_inner_div">
            <p
                onClick={() => dispatch(ChangeEventSettingPage("basic"))}
                className={
                  state === "basic"
                    ? "event_setting_subheader_active_btn"
                    : "event_setting_subheader_inactive_btn"
                }
              >
                Basic Details
              </p>
              <p
                onClick={() => dispatch(ChangeEventSettingPage("organizationInfo"))}
                className={
                  state === "organizationInfo"
                    ? "event_setting_subheader_active_btn"
                    : "event_setting_subheader_inactive_btn"
                }
              >
                Organization info
              </p>
              {/* <p
                onClick={() => dispatch(ChangeEventSettingPage("brandLogo"))}
                className={
                  state === "brandLogo"
                    ? "event_setting_subheader_active_btn"
                    : "event_setting_subheader_inactive_btn"
                }
              >
                Brand Logo
              </p>
              <p
                onClick={() => dispatch(ChangeEventSettingPage("brandWatermark"))}
                className={
                  state === "brandWatermark"
                    ? "event_setting_subheader_active_btn"
                    : "event_setting_subheader_inactive_btn"
                }
              >
                Brand Watermark
              </p> */}
              <p
                onClick={() => dispatch(ChangeEventSettingPage("integration"))}
                className={
                  state === "integration"
                    ? "event_setting_subheader_active_btn"
                    : "event_setting_subheader_inactive_btn"
                }
              >
                Website Integration
              </p>
            </div>
            <div className="event_setting_subheader_line"></div>
          </div>

          {state === "basic" && <Basic />}
          {state === "brandLogo" && <BrandLogo />}
          {state === "brandWatermark" && <BrandWatermark />}
          {state === "organizationInfo" && <OrgInfo />}
          {state === "integration" && <Integration />}
        </div>
      </div>
    </>
  );
};

export default Index;
