import React, { useState, useEffect, useCallback, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchImages, resetImages } from "./ViewPhotoReducer";
import "./viewphoto.css";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import PhotosLogo from "../../assets/events/photos.svg";
import { Link } from "react-router-dom";
import {
  Modal,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { saveAs } from "file-saver";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { TOKEN, dEV_API_URL } from "../../API";
import { resetAlbumList } from "../Album/Albums/AlbumListReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import CloseIcon from "../../assets/icons/close.svg";
import DownloadIcon from "../../assets/icons/download.svg";

const ViewPhoto = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [page, setPage] = useState(1);
  const [eventName, setEventName] = useState(null);
  const [uploadAlbumId, setUploadAlbumId] = useState();

  const [albumName, setAlbumName] = useState(null);
  const [photoCount, setPhotoCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  let queryParams = new URLSearchParams(window.location.search);
  let albumuuid = queryParams.get("album_uuid");

  // useEffect(() => {
  //   dispatch(resetAlbumList());
  //   dispatch(resetImages());
  // }, [dispatch, albumId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingImages(true);
      try {
        if (albumuuid != null) {
          const response = await fetch(
            `${dEV_API_URL}/events/images-list/?album_uuid=${albumuuid}&page=${page}&page_size=20`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          setEventName(data.event_name);
          setAlbumName(data.album_name);
          setUploadAlbumId(data.album_id);
          setPhotoCount(data.count);
          if (page === 1) {
            setImageUrls(data.results);
          } else {
            setImageUrls((prevImageUrls) => [
              ...prevImageUrls,
              ...data.results,
            ]);
          }
          setLoadingImages(false);
          setHasMore(data.next !== null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingImages(false);
      }
    };

    fetchData();
  }, [albumuuid, page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // const fetchData = useCallback(() => {
  //   if (Number.isInteger(imageUrls.length / 10 + 1)) {
  //     console.log(imageUrls.length / 10 + 1);
  //     dispatch(
  //       fetchImages({
  //         albumuuid: albumuuid,
  //         pageNumber: imageUrls.length / 20 + 1,
  //       })
  //     );
  //   }
  // }, [dispatch, albumId, imageUrls]);

  const handleOpenGalleryModal = () => {
    setOpenGalleryModal(true);
  };

  const handleCloseGalleryModal = () => {
    setOpenGalleryModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openImage = (index) => {
    setSelectedImageIndex(index);
    setOpenGalleryModal(true);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  const navigate = (direction) => {
    if (selectedImageIndex !== null) {
      const newIndex = selectedImageIndex + direction;
      if (newIndex >= 0 && newIndex < imageUrls.length) {
        setSelectedImageIndex(newIndex);
      }
    }
  };

  const downloadImage = async () => {
    try {
      if (selectedImageIndex !== null) {
        let imageName = "snapd.jpg";
        const imageUrl = imageUrls[selectedImageIndex].image;
        const response = await fetch(imageUrl, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  function getImageName(imageUrl) {
    const parts = imageUrl.split("/");
    const filenameWithQuery = parts[parts.length - 1];

    const filenameParts = filenameWithQuery.split("?");
    const filename = filenameParts[0];

    let imageName = filename;
    return imageName;
  }

  const handleCheckboxChange = (id) => {
    setSelectedImageIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );
  };

  const handleDeleteImages = async () => {
    setOpenConfirmationModal(true);
  };

  const confirmDeleteImages = async () => {
    try {
      const response = await deleteImages(selectedImageIds);
      console.log("Images deleted:", response);
      setSelectedImageIds([]);
      window.location.reload();
      toast.success("Images Deleted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error deleting images:", error);
    }
  };

  const deleteImages = async (imageIds) => {
    try {
      const dataToSend = {
        image_ids: selectedImageIds,
      };
      const response = await axios.delete(`${dEV_API_URL}/events/images/`, {
        headers: {
          "content-type": "application/json",
          Authorization: TOKEN,
        },
        data: dataToSend,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || "Failed to delete images");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow key pressed
        navigate(-1);
      } else if (event.keyCode === 39) {
        // Right arrow key pressed
        navigate(1);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <div>
      <Header />
      <div className="view_image_main_container">
        <SideBar />
        <div className="view_image_main_div">
          <div className="fixedHeader">
            <div className="heading_UploadBtn">
              <p className="heading_text">
                {eventName && albumName ? `${eventName} | ${albumName}` : ""}
              </p>
              <Link
                style={{ textDecoration: "none" }}
                to={`/upload-photos?albumId=${uploadAlbumId}`}
              >
                <div className="upload_btn">Upload Photos</div>
              </Link>
            </div>
            <div className="photo_subheader_card">
              <div className="photo_subheader_card_left">
                <div className="photo_subheader_card_left_img_div">
                  <img src={PhotosLogo} alt="photos" />
                </div>
                <p>Photos</p>
              </div>
              <div className="photo_subheader_card_right">
                <p>{photoCount}</p>
              </div>
            </div>
            {selectedImageIds.length > 0 && (
              <div className="delete_main_div">
                <div className="delete_count">
                  {selectedImageIds.length === 1
                    ? `${selectedImageIds.length} photo selected`
                    : `${selectedImageIds.length} photos selected`}
                </div>
                <div className="delete_container" onClick={handleDeleteImages}>
                  <DeleteOutlineIcon style={{ color: "white" }} />
                  <div className="delete_text">Delete</div>
                </div>
              </div>
            )}
          </div>
          <div className="infinite-scroll-container">
            <InfiniteScroll
              dataLength={imageUrls.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress />{" "}
                </div>
              }
              endMessage={<p className="image_no_more">No more images.</p>}
            >
              <div className="image-cards-container">
                {imageUrls.map((imageUrl, index) => (
                  <Card
                    key={index}
                    className="image-card"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#1f1236",
                    }}
                  >
                    <CardContent>
                      <Typography variant="body1" component="p">
                        {imageUrl.name} 
                      </Typography>
                      <img
                        src={imageUrl.image}
                        alt={`Image ${index}`}
                        className="card-image"
                        onClick={() => openImage(index)}
                      />
                      <div className="checkboxAndName">
                        <input
                          type="checkbox"
                          className="checkbox"
                          onChange={() => handleCheckboxChange(imageUrl.id)}
                          checked={selectedImageIds.includes(imageUrl.id)}
                        />
                        <div className="image_name">
                          {getImageName(imageUrl.image)}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </InfiniteScroll>
          </div>

          {selectedImageIndex !== null && (
            <Modal open={openGalleryModal} className="imageModal">
              <Box
                className="imageModal_box"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  background: "#0c0715",
                }}
              >
                <div className="view_photo_modal_inner_div">
                  <img 
                    style={
                      {
                        // height: "600px",
                        // width: "600px",
                        // objectFit: "contain",
                      }
                    }
                    src={imageUrls[selectedImageIndex].image}
                    alt={`Image ${selectedImageIndex}`}
                    className="modal-image"
                  />

                  <div className="modal_buttons_div">
                    <div className="modal_buttons_header">
                      <img
                        onClick={downloadImage}
                        src={DownloadIcon}
                        alt="download"
                      />
                      <img onClick={closeImage} src={CloseIcon} alt="close" />
                    </div>
                    <div>
                      <div className="modal_buttons_arrows">
                        <div className="modal_buttons_arrows_inner">
                          {selectedImageIndex > 0 && (
                            <div onClick={() => navigate(-1)}>
                              <ArrowBackIosNewIcon className="modal_buttons_arrow_icons" />
                            </div>
                          )}
                        </div>
                        <div className="modal_buttons_arrows_inner">
                          {selectedImageIndex < imageUrls.length - 1 && (
                            <div onClick={() => navigate(1)}>
                              <ArrowForwardIosIcon className="modal_buttons_arrow_icons" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          )}

          <Modal
            open={openConfirmationModal}
            onClose={() => setOpenConfirmationModal(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#1C1526",
                padding: "20px",
                width: "300px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <div className="delete_heading">Delete Photos</div>
              <div className="delete_sub_heading">
                Are you sure you want to delete these images?
              </div>
              <div className="confirm_delete">
                <div
                  className="cancel_button"
                  onClick={() => setOpenConfirmationModal(false)}
                >
                  Cancel
                </div>
                <div onClick={confirmDeleteImages} className="conifrm_button">
                  Confirm
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

      {error && <p>Error: {error.message}</p>}
    </div>
  );
};

export default ViewPhoto;