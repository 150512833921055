// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_main_container {
  width: 100%;
  height: 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #140f1c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 34px;
  position: fixed;
  z-index: 99;
}

.user_icon {
  color: white;
  font-size: 30px;
  margin-right: 20px;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin-right: 20px;

}

.dropdown_menu p {
  margin: 0;
  cursor: pointer;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;EACtB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,sBAAsB;EACtB,uCAAuC;EACvC,kBAAkB;EAClB,aAAa;EACb,kBAAkB;;AAEpB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,WAAW;AACb","sourcesContent":[".header_main_container {\n  width: 100%;\n  height: 65px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  background: #140f1c;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 15px 0 34px;\n  position: fixed;\n  z-index: 99;\n}\n\n.user_icon {\n  color: white;\n  font-size: 30px;\n  margin-right: 20px;\n}\n\n.dropdown_menu {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  background-color: #fff;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n  padding: 10px;\n  margin-right: 20px;\n\n}\n\n.dropdown_menu p {\n  margin: 0;\n  cursor: pointer;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
