import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useState } from "react";
import React from "react";
import "./loginScreen1.css";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import loginBackgorundImage from "../../../assets/loginScreen/loginscreen.jpeg";
import { style } from "@mui/system";
import { Button } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../loginSignupController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faL } from "@fortawesome/free-solid-svg-icons";
import GreenLogo from "../../../assets/footer/green.svg";
import YellowLogo from "../../../assets/footer/yellow.svg";
import OrangeLogo from "../../../assets/footer/orange.svg";
import TealLogo from "../../../assets/footer/teal.svg";
import PinkLogo from "../../../assets/footer/pink.svg";
import RedLogo from "../../../assets/footer/red.svg";
import "animate.css/animate.css";

const LoginScreen = () => {
  return (
    <div className="loginContainer">
      <img
        className="loginBackgroundImage"
        src={loginBackgorundImage}
        alt="Login Background Image"
      />

      <div className="loginCenterContrainer">
        <div className="rightText">
          <p>Elevate Your Photo</p>
          <p>Recognition Experience</p>
          <p style={{ display: "flex" }}>
            {" "}
            with
            {/* <h1 className="text">SNAPD!!</h1> */}
            <section class="animation">
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={YellowLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={OrangeLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={TealLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={PinkLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={RedLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
            </section>
          </p>
        </div>
        <LoginFormDesing />
        <ToastContainer />
      </div>
    </div>
  );
};

const LoginFormDesing = () => {
  return (
    <div className="logInfromContianer">
      <p>Login</p>
      <p style={{ fontSize: 16 }}>
        Welcome to Snapd !! We're here to make it a seamless and enjoyable
        experience.
      </p>
      <LoginForm />
    </div>
  );
};

const LoginForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // Access navigate function from React Router
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const initialValues = {
    email: "",
    password: "",
  };

  const navigateToForgtPassword = () => {
    navigate("/forGotPassword");
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("submit button is clicked");
    console.log("submit button is clicked values are=", values);

    setIsSubmitting(true);
    const LoginUserData = {
      email: values.email,
      password: values.password,
    };
    await userLogin(LoginUserData, { setIsSubmitting, navigate });
  };

  const goingToSignup = () => {
    navigate("/signUp");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ touched, errors }) => (
        <Form>
          <div>
            <p className="loginLables">Email</p>
            <div>
              <Field
                type="email"
                name="email"
                placeholder="example.email@gmail.com"
                className="signUptextFields"
                maxLength={100}
              />
              {touched.email && (
                <ErrorMessage
                  name="email"
                  component="div"
                  className="loginscreen_validation_error"
                />
              )}
            </div>
          </div>

          <div className="signUplabeledittextGroup" style={{ marginTop: 16 }}>
            <label className="signUPLabels">Password</label>

            <Field
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              className="signUptextFields"
              maxLength={20}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              onClick={() => setShowPassword(!showPassword)}
              className="eyecontinaer"
            />
            {touched.password && (
              <ErrorMessage
                name="password"
                component="div"
                className="loginscreen_validation_error"
              />
            )}
            <Button
              className="forgotpassword"
              onClick={navigateToForgtPassword}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                border: "none",
                float: "right",
                cursor: "pointer",
              }}
            >
              Forgot Password?
            </Button>
          </div>

          <button
            className="signupSubmitbutton"
            type="submit"
            style={{
              cursor: "pointer",
            }}
          >
            {" "}
            Login
          </button>
          <div className="noAccoutnSignUp">
            <span className="loginLables">Don’t have an account?</span>
            <span
              className="forgotpassword"
              onClick={goingToSignup}
              style={{
                cursor: "pointer",
              }}
            >
              {" "}
              Sign up
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Logintext = () => {
  return (
    <div className="loginText">
      <h1>Login</h1>
      <h1 className="loginSubtitle">
        Welcome to our platform! Please enter your credentials to log in and
        access your account
      </h1>
    </div>
  );
};

export default LoginScreen;
