import React, { useState, useEffect } from "react";
import "./albumCard.css";
import axios from "axios";

import NoImg from "../../../../assets/NoImg.svg";

import PhotosCount from "../../../../assets/events/photoCount.svg";
import UploadImg from "../../../../assets/events/upload.svg";
import ShareImg from "../../../../assets/events/share.svg";
import SettingImg from "../../../../assets/events/setting.svg";
import ManageAlbumImg from "../../../../assets/events/manageAlbum.svg";
import DeleteEventImg from "../../../../assets/events/deleteEvent.svg";
import { Link } from "react-router-dom";
import DeleteAlbum from "../../DeleteAlbum/DeleteAlbum";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import ShareAlbum from "../../ShareAlbum/ShareAlbum";
import { TOKEN, dEV_API_URL } from "../../../../API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";

const AlbumCard = ({ respData, eventId, eventuuid }) => {
  const [deleteAlbumModal, setDeleteAlbumModal] = useState(false);
  const [selectedAlbumId, setSelectedAlbumId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [shareAlbumModal, setShareAlbumModal] = useState(false);

  const [selectedAlbumData, setSelectedAlbumData] = useState({
    albumId: null,
    photosCount: null,
  });
 
  const [switchStates, setSwitchStates] = useState({});
  const [screenSize, setScreenSize] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenSize(window.innerWidth);

      const actualSize = () => {
        setScreenSize(window.innerWidth);
      };

      window.addEventListener("resize", actualSize);
      return () => {
        window.removeEventListener("resize", actualSize);
      };
    }
  }, [screenSize]);

  useEffect(() => {
    if (screenSize < 1024 && screenSize > 600) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [screenSize]);

  // useEffect(() => {
  //   console.log(showTooltip);
  // }, [showTooltip])

  useEffect(() => {
    // Step 2: Update switch states based on respData
    const switchStatesInit = {};
    respData.forEach((item) => {
      switchStatesInit[item.id] = item.published || false;
    });
    setSwitchStates(switchStatesInit);
  }, [respData]);

  const handleSwitchChange = async (item) => {
    const newSwitchValue = !switchStates[item.id];

    // Step 5: Call the updateEventApi function when the switch changes
    await updateAlbum(item, newSwitchValue);

    // Step 6: Update switch state after successful API request
    setSwitchStates((prevState) => ({
      ...prevState,
      [item.id]: newSwitchValue,
    }));
  };

  const updateAlbum = (item, publishVal) => {
    const data = {
      album_id: item.id,
      title: item.title,
      description: item.description,
      facial_search_active: item.facial_search_active,
      can_user_view_all_images_album_wide:
        item.can_user_view_all_images_album_wide,
      published: publishVal,
    };

    axios({
      method: "PATCH",
      url: `${dEV_API_URL}/events/albums/`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
      data,
    })
      .then((res) => {
        toast.success(
          `Album ${publishVal === true ? "Published" : "Unpublished"}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((err) => {
        // console.log(err);
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // setPoststatus(false);
      })
      .finally(() => {
        // setPoststatus(false);
      });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(17px)",
        color: "#381E72",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#D0BCFF" : "#D0BCFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 16,
      height: 16,
      borderRadius: 50,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 40 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#534B61",
      boxSizing: "border-box",
    },
  }));

  const handleOpen = (albumId, photosCount) => {
    setSelectedAlbumData({
      albumId,
      photosCount,
    });
    setDeleteAlbumModal(true);
  };

  const handleClose = () => {
    setSelectedAlbumData({
      albumId: null,
      photosCount: null,
    });
    setDeleteAlbumModal(false);
  };

  const openShareModal = (albumId, id) => {
    setSelectedAlbumId(albumId);
    setShareAlbumModal(true);
    setSelectedId(id);
  };

  const closeShareModal = () => {
    // setSelectedEventId(null);
    setShareAlbumModal(false);
  };
  const navigate = useNavigate();
  const navigateToAlbums = (item) => {
    navigate(`/photos?album_uuid=${item}`);
  };
  return (
    <>
      {respData.map((item) => {
        return (
          <div
            className="event_card_content"
            onClick={() => navigateToAlbums(item.uuid)}
          >
          <div className="album_card" key={item.id}>
            <div className="album_img_card">
              {item.album_cover_image ? (
                <div className="album_img_div">
                  <img src={item.album_cover_image} alt="album_image" />
                </div>
              ) : (
                <div className="album_no_img_div">
                  <img src={NoImg} alt="album_image" />
                  <p>No Image Available</p>
                </div>
              )}
            </div>
            <div className="album_info_div">
              <div className="album_info_title_toogle_div">
                <p className="album_name">{item.title}</p>
                <div
                  className="album_info_toogle_div"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <AntSwitch
                      checked={switchStates[item.id] || false}
                      onChange={() => handleSwitchChange(item)}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </Stack>
                  <p className="album_info_publish_p">
                    {switchStates[item.id] ? "Published" : "Unpublish"}
                  </p>
                </div>
              </div>
              <p className="album_description">{item.description}</p>
              <div className="album_count_div">
                <div className="album_count_card">
                  <img src={PhotosCount} alt="photos" />
                  <p> {item.image_count_in_album} </p>
                </div>
              </div>

              <div className="album_btns">
                <Link
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: "none" }}
                  to={`/upload-photos?albumId=${item.id}`}
                >
                  <Tooltip
                    title={showTooltip ? "Upload Photos" : ""}
                    placement="top"
                  >
                    <div className="album_btn_div">
                      <img src={UploadImg} alt="copy_link" />
                      <p>Upload Photos</p>
                    </div>
                  </Tooltip>
                </Link>

                <Tooltip title={showTooltip ? "Share" : ""} placement="top">
                  <div
                    className="album_btn_div"
                    onClick={(e) => {
                      e.stopPropagation();
                      openShareModal(item.uuid, item.id);
                    }}
                  >
                    <img src={ShareImg} alt="copy_link" />
                    <p>Share</p>
                  </div>
                </Tooltip>

                <Link
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: "none" }}
                  to={`/album-setting?albumId=${item.id}&eventId=${eventId}&uuid=${eventuuid}`}
                >
                  <Tooltip
                    title={showTooltip ? "Album Settings" : ""}
                    placement="top"
                  >
                    <div className="album_btn_div">
                      <img src={SettingImg} alt="copy_link" />
                      <p>Album Settings</p>
                    </div>
                  </Tooltip>
                </Link>

                <Link
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: "none" }}
                  to={`/photos?album_uuid=${item.uuid}`}
                >
                  <Tooltip
                    title={showTooltip ? "View Photos" : ""}
                    placement="top"
                  >
                    <div className="album_btn_div">
                      <img src={ManageAlbumImg} alt="copy_link" />
                      <p>View Photos</p>
                    </div>
                  </Tooltip>
                </Link>

                <Tooltip
                  title={showTooltip ? "Delete Album" : ""}
                  placement="top"
                >
                  <div
                    className="album_btn_div"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpen(item.id, item.image_count_in_album);
                    }}
                  >
                    <img src={DeleteEventImg} alt="copy_link" />
                    <p>Delete Album</p>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          </div>
        );
      })}

      <DeleteAlbum
        open={deleteAlbumModal}
        onClose={handleClose}
        selectedAlbumData={selectedAlbumData}
        eventId={eventuuid}
      />

      <ShareAlbum
        open={shareAlbumModal}
        onClose={closeShareModal}
        albumId={selectedAlbumId}
        selectedId={selectedId}
      />
    </>
  );
};

export default AlbumCard;
