import React, { useEffect, useState } from "react";
import "../brandWatermark/brandWatermark.css";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import HorizontalDummyImg from "../../../assets/eventSetting/hor_preview.png";
import VerticalDummyImg from "../../../assets/eventSetting/ver_preview.png";
import { useParams } from "react-router-dom";
import { TOKEN, dEV_API_URL } from "../../../API";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";


const BrandWatermark = () => {
  const navigate = useNavigate();

  const [coverImg, setCoverImg] = useState(null);
  const [postStatus, setPoststatus] = useState(false);

  const [watermarkHorizontalImg, setWatermarkHorizontalImg] = useState(null);
  const [watermarkVerticalImg, setWatermarkVerticalImg] = useState(null);

  const [isHorizontalImgChange, setIsHorizontalImgChange] = useState(false);
  const [isVerticalImgImgChange, setIsVerticalImgImgChange] = useState(false);

  const [flag, setFlag] = useState(false);

  const [respData, setRespData] = useState([]);
  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");


  let validationSchema = Yup.object({
    brand_name: Yup.string()
      .min(3, "Brand name must be atleast 3 characters")
      .max(150, "Brand name must be at most 150 characters")
      .required("Brand name is required"),
      subtitle: Yup.string()
      .min(3, "Subtitle must be atleast 3 characters")
      .max(150, "Subtitle must be at most 150 characters")
      .required("Subtitle is required"),
      cta: Yup.string()
      .min(3, "CTA must be atleast 3 characters")
      .max(150, "CTA must be at most 150 characters")
      .required("CTA is required"),
  });

  useEffect(() => {
    getWatermarkDetail(id);
  }, [id]);

  const getWatermarkDetail = (id) => {
    axios({
      method: "GET",
      url: `${dEV_API_URL}/branding/brand-watermark/?event_id=${id}`,
      headers: {
        "content-type": "application/json",
        Authorization:
        TOKEN,           },
    })
      .then((resp) => {
        setRespData(resp.data);
        setWatermarkHorizontalImg(resp.data.horizontal_watermark);
        setWatermarkVerticalImg(resp.data.vertical_watermark);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  };

  const updateWatermarkDetail = (values) => {
    setPoststatus(true);

    var MethodType;

    var Id;
    if (flag) {
      Id = respData.id;
      MethodType = "PATCH";
    } else {
      MethodType = "POST";
    }

    const formData = new FormData();
    if (respData.id) {
      formData.append("watermark_id", Id);
    } else {
      formData.append("event_id", id);
    }
    if (isHorizontalImgChange) {
      formData.append("horizontal_watermark", watermarkHorizontalImg);
    }
    if (isVerticalImgImgChange) {
      formData.append("vertical_watermark", watermarkVerticalImg);
    }

    axios({
      method: MethodType,
      url: `${dEV_API_URL}/branding/brand-watermark/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization:
        TOKEN,     
           },
      data: formData,
    })
      .then((res) => {
        toast.success('Brand Watermark Detail Updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      })
      .catch((err) => {
        toast.warning('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedTypes.includes(file.type)) {
      return true;
    } else {
      alert(`Invalid file type. Please select a PNG, JPG, or JPEG image.`);
      return false;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      setCoverImg(file);
    }
  };

  return (
    <>
      <div className="watermark_main_container">
        <div className="watermark_header_div">
          <p>Watermark on photos</p>
          <p>Please upload branding if you want to add branding on photos</p>
        </div>

        <Formik
          initialValues={{
 
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
          }}
        >
          {({ values, isValid, setFieldValue, errors, touched, resetForm }) => (
            <Form>
              <div className="watermark_photos_div">
                <div className="watermark_photos_main_div">
                  <div className="watermark_photos_horizontal_div">
                    <>
                      <div
                        className="watermark_logo_upload_box"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <p className="watermark_photos_custom_file_upload_label">
                          On Horizontal Photos
                        </p>

                        <>
                          <label
                            htmlFor="watermarkHorizontalImg"
                            className="watermark_photos_custom_file_upload"
                          >
                            <Field
                              type="file"
                              id="watermarkHorizontalImg"
                              name="watermarkHorizontalImg"
                              className="watermark_photos_upload_input"
                              accept="image/png, image/jpeg, image/jpg"
                              style={{ display: "none" }}
                              value={values.file}
                              onChange={(e) => {
                                if (
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/jpg"
                                ) {
                                  setWatermarkHorizontalImg(e.target.files[0]);
                                  setIsHorizontalImgChange(true);
                                }
                              }}
                            />
                            <p className="watermark_photos_upload_box_p1">
                              <span>Choose</span> file to upload
                            </p>
                            <p className="watermark_photos_upload_box_p2">or</p>
                            <p className="watermark_photos_upload_box_p3">
                              Drag & drop image to upload
                            </p>
                            <p className="watermark_photos_upload_box_p4">
                              Recommended: 269x186 | jpeg, jpg, png | max. size:
                              2MB
                            </p>
                          </label>
                          <ErrorMessage
                            name="coverimg"
                            component="div"
                            className="watermark_photos_error"
                          />
                        </>
                      </div>
                    </>
                    <div>
                      <p className="watermark_photos_preview_heading">Preview</p>

                      <div className="watermark_photos_horizontal_preview_div">
                        <img
                          className="watermark_photos_horizontal_preview_dummy_img"
                          src={HorizontalDummyImg}
                          alt="horizontal_img"
                        />
                        
                        {isHorizontalImgChange ? 
                          <img
                            className="watermark_photos_horizontal_preview_upload_img"
                            src={URL.createObjectURL(watermarkHorizontalImg)}
                            alt="horizontal_img"
                          /> :
                          <img
                            className="watermark_photos_horizontal_preview_upload_img"
                            src={watermarkHorizontalImg}
                            alt="horizontal_img"
                          /> }
                        
                      </div>
                    </div>
                  </div>
                  <div className="watermark_photos_vertical_div">
                    <>
                      <div
                        className="watermark_logo_upload_box"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <p className="watermark_photos_custom_file_upload_label">
                          On Vertical Photos
                        </p>
                        <>
                          <label
                            htmlFor="watermarkVerticalImg"
                            className="watermark_photos_custom_file_upload"
                          >
                            <Field
                              type="file"
                              id="watermarkVerticalImg"
                              name="watermarkVerticalImg"
                              className="watermark_photos_upload_input"
                              accept="image/png, image/jpeg, image/jpg"
                              style={{ display: "none" }}
                              value={values.file}
                              onChange={(e) => {
                                if (
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/jpg"
                                ) {
                                  setWatermarkVerticalImg(e.target.files[0]);
                                  setIsVerticalImgImgChange(true);
                                }
                              }}
                            />
                            <p className="watermark_photos_upload_box_p1">
                              <span>Choose</span> file to upload
                            </p>
                            <p className="watermark_photos_upload_box_p2">or</p>
                            <p className="watermark_photos_upload_box_p3">
                              Drag & drop image to upload
                            </p>
                            <p className="watermark_photos_upload_box_p4">
                              Recommended: 269x186 | jpeg, jpg, png | max. size:
                              2MB
                            </p>
                          </label>
                          <ErrorMessage
                            name="coverimg"
                            component="div"
                            className="watermark_photos_error"
                          />
                        </>
                      </div>
                    </>
                    <div className="watermark_photos_vertical_preview_div_container">
                    <div className="watermark_photos_vertical_preview_div">
                      <img
                        className="watermark_photos_vertical_preview_dummy_img"
                        src={VerticalDummyImg}
                        alt="vertical_img"
                      />
                      {isVerticalImgImgChange ? 
                        <img
                          className="watermark_photos_vertical_preview_upload_img"
                          src={URL.createObjectURL(watermarkVerticalImg)}
                          alt="vertical_img"
                        /> :
                        <img
                          className="watermark_photos_vertical_preview_upload_img"
                          src={watermarkVerticalImg}
                          alt="vertical_img"
                        />
                      }
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="watermark_form_btns">
                {postStatus ? (
                  <p className="watermark_form_submit_btn">
                    <span>
                    <ClipLoader
                            color="white"
                            size={24}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                    </span>
                  </p>
                ) : (
                  <>
                    <p className="watermark_form_cancel_btn">
                      <span
                        onClick={() => {
                          resetForm();
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </span>
                    </p>
                    { (watermarkHorizontalImg != null && watermarkVerticalImg != null) ? 
                    <button onClick={() => updateWatermarkDetail()}
                      className="watermark_form_submit_btn"
                    >
                      <span>Save</span>
                    </button> :
                     <button onClick={() => {
                      alert("please upload images")
                     }}
                     className="watermark_form_submit_btn"
                   >
                     <span>Save</span>
                   </button> }
                  </> 
                 
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BrandWatermark;
