import React, { useEffect, useState } from "react";
import "../brandLogo/brandLogo.css";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import HorizontalDummyImg from "../../../assets/eventSetting/hor_preview.png";
import VerticalDummyImg from "../../../assets/eventSetting/ver_preview.png";
import { TOKEN, dEV_API_URL } from "../../../API";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";


const BrandLogo = () => {
  const navigate = useNavigate();

  const [coverImg, setCoverImg] = useState(null);
  const [postStatus, setPoststatus] = useState(false);

  const [selectedPosition, setSelectedOption] = useState("");

  const [horizontalImg, setHorizontalImg] = useState(null);
  const [verticalImg, setVerticalImg] = useState(null);

  const [isHorizontalImgChange, setIsHorizontalImgChange] = useState(false);
  const [isVerticalImgImgChange, setIsVerticalImgImgChange] = useState(false);

  const [flag, setFlag] = useState(false);

  const [respData, setRespData] = useState([]);

  const [horizontalImgPosition, setHorizontalImgPosition] = useState({
    align: "flex-start",
    justify: "flex-end",
  });

  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");

  const PositionList = [
    { id: 1, value: "top_left" },
    // { id: 2, value: "top_center" },
    { id: 3, value: "top_right" },
    // { id: 4, value: "middle_left" },
    // { id: 5, value: "middle_center" },
    // { id: 6, value: "middle_right" },
    { id: 7, value: "bottom_left" },
    // { id: 8, value: "bottom_center" },
    { id: 9, value: "bottom_right" },
  ];

  let validationSchema = Yup.object({
    selectedOption: Yup.string(),
  });

  useEffect(() => {
    getBrandLogoDetail(id);
  }, [id]);

  const getBrandLogoDetail = (id) => {

    axios({
      method: "GET",
      url: `${dEV_API_URL}/branding/brand-image/?event_id=${id}`,
      headers: {
        "content-type": "application/json",
        Authorization:
        TOKEN,      },
    })
      .then((resp) => {
        setRespData(resp.data);
        setHorizontalImg(resp.data.horizontal_brand_image);
        setVerticalImg(resp.data.vertical_brand_image);
        setSelectedOption(resp.data.position);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  };

  const updateBrandLogoDetail = (values) => {
    setPoststatus(true);

    var MethodType;

    var Id;
    if (flag) {
      Id = respData.id;
      MethodType = "PATCH";
    } else {
      MethodType = "POST";
    }

    const formData = new FormData();
    if (respData.id) {
      formData.append("brand_id", Id);
    } else {
      formData.append("event_id", id);
    }
    if (isHorizontalImgChange) {
      formData.append("horizontal_brand_image", horizontalImg);
    }
    if (isVerticalImgImgChange) {
      formData.append("vertical_brand_image", verticalImg);
    }
    formData.append("position", selectedPosition);

    axios({
      method: MethodType,
      url: `${dEV_API_URL}/branding/brand-image/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization:
        TOKEN,
               },
      data: formData,
    })
      .then((res) => {
        toast.success('Brand Logo Detail Updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      })
      .catch((err) => {
        toast.warning('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedTypes.includes(file.type)) {
      return true;
    } else {
      alert(`Invalid file type. Please select a PNG, JPG, or JPEG image.`);
      return false;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      setCoverImg(file);
    }
  };

  function convertToTitleCase(str) {
    const words = str.split("_");

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseStr = titleCaseWords.join(" ");

    return titleCaseStr;
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const positionMappings = {
      top_left: { align: "flex-start", justify: "flex-start" },
      // top_center: { align: "flex-start", justify: "center" },
      top_right: { align: "flex-start", justify: "flex-end" },
      // middle_left: { align: "center", justify: "flex-start" },
      // middle_center: { align: "center", justify: "center" },
      // middle_right: { align: "center", justify: "flex-end" },
      bottom_left: { align: "flex-end", justify: "flex-start" },
      // bottom_center: { align: "flex-end", justify: "center" },
      bottom_right: { align: "flex-end", justify: "flex-end" },
    };

    setHorizontalImgPosition(
      positionMappings[selectedPosition] || {
        align: "flex-start",
        justify: "flex-end",
      }
    );
  }, [selectedPosition]);

  return (
    <> 
      <div className="brand_logo_main_container">
        <div className="brand_logo_header_div">
          <p>Branding on photos</p>
          <p>Please upload branding if you want to add branding on photos</p>
        </div>

        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            updateBrandLogoDetail(values);
          }}
        >
          {({ values, isValid, setFieldValue, errors, touched, resetForm }) => (
            <Form>
              <div className="brand_logo_photos_div">
                <div className="brand_logo_photos_main_div">
                  <div className="brand_logo_photos_horizontal_div">
                    <>
                      <div
                        className="brand_logo_upload_box"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <p className="brand_logo_photos_custom_file_upload_label">
                          On Horizontal Photos
                        </p>

                        <>
                          <label
                            htmlFor="horizontalImg"
                            className="brand_logo_photos_custom_file_upload"
                          >
                            <Field
                              type="file"
                              id="horizontalImg"
                              name="horizontalImg"
                              className="brand_logo_photos_upload_input"
                              accept="image/png, image/jpeg, image/jpg"
                              style={{ display: "none" }}
                              value={values.file}
                              onChange={(e) => {
                                if (
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/jpg"
                                ) {
                                  setHorizontalImg(e.target.files[0]);
                                  setIsHorizontalImgChange(true);
                                }
                              }}
                            />
                            <p className="brand_logo_photos_upload_box_p1">
                              <span>Choose</span> file to upload
                            </p>
                            <p className="brand_logo_photos_upload_box_p2">
                              or
                            </p>
                            <p className="brand_logo_photos_upload_box_p3">
                              Drag & drop image to upload
                            </p>
                            <p className="brand_logo_photos_upload_box_p4">
                              Recommended: 250x150 | jpeg, jpg, png | max. size:
                              2MB
                            </p>
                          </label>
                          <ErrorMessage
                            name="coverimg"
                            component="div"
                            className="brand_logo_photos_error"
                          />
                        </>
                      </div>

                      <div className="brand_logo_box_div">
                        <label htmlFor="selectedPosition">Position</label>
                        <div className="brand_logo_select_box_div">
                          <Field
                            as="select"
                            id="selectedPosition"
                            name="selectedOption"
                            className="brand_logo_select_box"
                            onChange={handleChange}
                          >
                            <option
                              className="brand_logo_first_option"
                              value=""
                              hidden
                            >
                              Select
                            </option>
                            {PositionList.map((item) => {
                              return (
                                <option
                                  value={item.value}
                                  label={convertToTitleCase(item.value)}
                                />
                              );
                            })}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="selectedOption"
                          component="div"
                          className="event_basic_info_error"
                        />
                      </div>
                    </>
                    <div>
                      <p className="brand_logo_photos_preview_heading">
                        Preview
                      </p>

                      <div className="brand_logo_photos_horizontal_preview_div">
                        <img
                          className="brand_logo_photos_horizontal_preview_dummy_img"
                          src={HorizontalDummyImg}
                          alt="horizontal_img"
                        />

                        <div
                          style={{
                            alignItems: `${horizontalImgPosition.align}`,
                            justifyContent: `${horizontalImgPosition.justify}`,
                          }}
                          className="brand_logo_photos_horizontal_preview_upload_img_div"
                        >
                          {!isHorizontalImgChange ? (
                            <img
                              className="brand_logo_photos_horizontal_preview_upload_img"
                              src={horizontalImg}
                              alt="logo"
                            />
                          ) : (
                            <img className="brand_logo_photos_horizontal_preview_upload_img"
                              src={URL.createObjectURL(horizontalImg)}
                              alt="logo"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="brand_logo_photos_vertical_div">
                    <>
                      <div
                        className="brand_logo_upload_box"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <p className="brand_logo_photos_custom_file_upload_label">
                          On Vertical Photos
                        </p>
                        <>
                          <label
                            htmlFor="verticalImg"
                            className="brand_logo_photos_custom_file_upload"
                          >
                            <Field
                              type="file"
                              id="verticalImg"
                              name="verticalImg"
                              className="brand_logo_photos_upload_input"
                              accept="image/png, image/jpeg, image/jpg"
                              style={{ display: "none" }}
                              value={values.file}
                              onChange={(e) => {
                                if (
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/jpg"
                                ) {
                                  setVerticalImg(e.target.files[0]);
                                  setIsVerticalImgImgChange(true);
                                }
                              }}
                            />
                            <p className="brand_logo_photos_upload_box_p1">
                              <span>Choose</span> file to upload
                            </p>
                            <p className="brand_logo_photos_upload_box_p2">
                              or
                            </p>
                            <p className="brand_logo_photos_upload_box_p3">
                              Drag & drop image to upload
                            </p>
                            <p className="brand_logo_photos_upload_box_p4">
                              Recommended: 150x250 | jpeg, jpg, png | max. size:
                              2MB
                            </p>
                          </label>
                          <ErrorMessage
                            name="coverimg"
                            component="div"
                            className="brand_logo_photos_error"
                          />
                        </>
                      </div>
                    </>
                    <div className="brand_logo_photos_vertical_preview_container">
                      <div className="brand_logo_photos_vertical_preview_div">
                        <img
                          className="brand_logo_photos_vertical_preview_dummy_img"
                          src={VerticalDummyImg}
                          alt="vertical_img"
                        />

                        <div
                          style={{
                            alignItems: `${horizontalImgPosition.align}`,
                            justifyContent: `${horizontalImgPosition.justify}`,
                          }}
                          className="brand_logo_photos_vertical_preview_upload_img_div"
                        >
                       
                          {!isVerticalImgImgChange? (
                              <img 
                              className="brand_logo_photos_vertical_preview_upload_img"
                              src={verticalImg} alt="logo" />
                            ) : (
                              <img
                              className="brand_logo_photos_vertical_preview_upload_img"
                                src={URL.createObjectURL(verticalImg)}
                                alt="logo"
                              />
                            )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="brand_logo_form_btns">
                {postStatus ? (
                  <p className="brand_logo_form_submit_btn">
                    <span>
                    <ClipLoader
                    color="white"
                    size={24}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                    </span>
                  </p>
                ) : (
                  <>
                    <p className="brand_logo_form_cancel_btn">
                      <span
                        onClick={() => {
                          resetForm();
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </span>
                    </p>
                    { (horizontalImg != null && verticalImg != null) ?
                    <button
                      className="brand_logo_form_submit_btn"
                      disabled={!isValid}
                      type="submit"
                    >
                      <span>Save</span>
                    </button> :
                     <button
                     className="brand_logo_form_submit_btn"
                     onClick={() => {
                      alert("please upload images")
                     }}
                   >
                     <span>Save</span>
                   </button> }
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BrandLogo;
