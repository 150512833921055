import React, { useEffect, useCallback, useState } from "react";
import "../Albums/albums.css";
import SideBar from "../../../components/sidebar/SideBar";
import Header from "../../../components/header/Header";
import AlbumLogo from "../../../assets/events/solidAlbum.svg";
import PhotosLogo from "../../../assets/events/photos.svg";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import AlbumCard from "./AlbumCard/AlbumCard";

import { useDispatch, useSelector } from "react-redux";
import { fetchAlbumList } from "./AlbumListReducer";
import SyncLoader from "react-spinners/SyncLoader";
import NoEvent from "../../../assets/events/NoEvent.gif"
import InfiniteScroll from "react-infinite-scroll-component";
import { resetAlbumList } from "./AlbumListReducer";

const Albums = () => {
  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");
  let eventuuid = queryParams.get("uuid");

  const override = {
    width: "100%",
    display: "flex", 
    gap: "6px",
    alignItem: "center",
    justifyContent: "center",
    margin: "200px 0 0 0",
  };

  const dispatch = useDispatch();
  const { respData, headerData, status, hasMore,newEventID } = useSelector(
    (state) => state.albumList
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(() => {
    dispatch(fetchAlbumList({ eventId: id, pageNumber: respData.length / 10 + 1 }));
  }, [dispatch,id, respData]);

  return (
    <>
      <Header />
      <div className="album_main_container">
        <SideBar />
        <div className="album_main_div">
          <div className="album_header">
            <div className="album_header_left">
              <p>My Albums</p>
            </div>
            <div className="album_header_right">
              <Link
                style={{ textDecoration: "none" }}
                className="album_header_right_btn_div"
                to={`/create-album?eventId=${newEventID}&uuid=${id}`}
              >
                <button>Create Album</button>
              </Link>
            </div>
          </div>
          <div className="album_subheader">
            <div className="album_subheader_card">
              <div className="album_subheader_card_left">
                <div className="album_subheader_card_left_img_div">
                  <img src={AlbumLogo} alt="albums" />
                </div>
                <p>Album</p>
              </div>
              <div className="album_subheader_card_right">
                <p> {headerData.count} </p>
              </div>
            </div>
            <div className="album_subheader_card">
              <div className="album_subheader_card_left">
                <div className="album_subheader_card_left_img_div">
                  <img src={PhotosLogo} alt="photos" />
                </div>
                <p>Photos</p>
              </div>
              <div className="album_subheader_card_right">
                <p>{headerData.number_of_images_across_albums}</p>
              </div>
            </div>
          </div>
          {respData.length === 0 && status === "loading" ? (
            <SyncLoader
              color="#E5DAF2"
              loading="...loading"
              cssOverride={override}
              size={6}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> 
          ) : (
            <> 
              {respData.length > 0 ? (
                 <InfiniteScroll
                 dataLength={respData.length}
                 next={fetchData}
                 hasMore={hasMore}
                 loader={<p>Loading......</p>}
                 endMessage={<p className="album_list_no_more">No more albums.</p>}
               >
                <AlbumCard respData={respData} eventId={id} eventuuid={eventuuid} />
                </InfiniteScroll>
              ) : (
                <div className="no_album_found_div">
                  <img 
                  src={NoEvent}
                  alt="img" />
                  <p>No album Available</p>
                </div>
              )}
            </>
          )}

        </div>
      </div>
    </>
  );
};

export default Albums;
