import "react-toastify/dist/ReactToastify.css";

import React from "react";
import "../app/App.css";
import RouterPath from "../router/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <RouterPath />
      </header>

      <ToastContainer/>
    </div>
  );
};

export default App;
