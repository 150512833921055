import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../header/Header.css";
import Logo from "../../assets/dashboardlogo.svg";
import UserPic from "../../assets/dummyUser.svg";
import { FaRegUser } from "react-icons/fa";
import Cookies from "js-cookie";

const Header = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    const allCookies = Cookies.get();
    // Loop through all cookies and remove each one
    for (const cookie in allCookies) {
      Cookies.remove(cookie);
    }
    navigate("/", { replace: true });
  };
  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="header_main_container">
        <div className="header_main_left">
          <img src={Logo} alt="logo" onClick={handleLogoClick} />
        </div>
        <div className="header_main_right" onClick={toggleDropdown}>
          <FaRegUser className="user_icon" />
          {isDropdownOpen && (
            <div className="dropdown_menu">
              {/* Your dropdown menu content */}
              <p onClick={handleLogout}>Logout</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
