import React from "react";
import "../price/price.css";
import Header from "../header/Header";
import Footer from "../../components/footer/Footer";
import DoneIcon from "../../assets/price/done.svg";

const Price = () => {
  return (
    <>
      <div className="price_wrapper">
        <div className="price_inner">
          <div className="price_main_div">
            <div>
              <div className="about_header_div">
                <Header />
              </div>
              <div className="price_content_div">
                <p className="price_main_heading">Pricing</p>
                <p className="price_sub_heading">
                  Pixel-Perfect Pricing: Unveiling Cost Structures for an
                  Unforgettable Photo Sharing Journey
                </p>

                <div className="price_cards">
                  <div className="price_card">
                    <div className="price_card_header">
                      <p className="price_card_type">Trial Version</p>
                      <p className="price_card_mode">Free</p>
                      <p className="price_card_price">100 photos </p>
                      <p className="price_card_per_photo">
                        Price per photo upload
                      </p>
                    </div>
                    <div className="price_card_content">
                    <div className="price_card_content_div">
                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Distribute photos for free</p>
                            <p>allow free photo access to guests</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Distribute photos for free</p>
                            <p>allow free photo access to guests</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Branding</p>
                            <p>
                              promote your brand to guests and their friends
                            </p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Unlimited Guests</p>
                            <p>no limit on guests or selfies</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>1 years</p>
                            <p>photos storage and access duration</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Website Integration</p>
                            <p>integrate event access with your website</p>
                          </div>
                        </div>
                      </div>
                      <button className="price_card_btn">Get Started</button>
                    </div>
                  </div>

                  <div className="price_card_active">
                    <div className="price_card_header">
                      <p className="price_card_type">Single event use</p>
                      <p className="price_card_mode">Starts at</p>
                      <p className="price_card_price">
                        ₹ 1.5 / photo (excl. GST)
                      </p>
                      <p className="price_card_per_photo">
                        Price per photo upload
                      </p>
                    </div>
                    <div className="price_card_content">
                      <div className="price_card_content_div">
                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Distribute photos for free</p>
                            <p>allow free photo access to guests</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Distribute photos for free</p>
                            <p>allow free photo access to guests</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Branding</p>
                            <p>
                              promote your brand to guests and their friends
                            </p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Unlimited Guests</p>
                            <p>no limit on guests or selfies</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>1 years</p>
                            <p>photos storage and access duration</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Website Integration</p>
                            <p>integrate event access with your website</p>
                          </div>
                        </div>
                      </div>
                      <button className="price_card_btn">Contact Us</button>
                    </div>
                  </div>

                  <div className="price_card">
                    <div className="price_card_header">
                      <p className="price_card_type">
                        Recurring - multi event use
                      </p>
                      <p className="price_card_mode"></p>
                      <p className="price_card_price">Contact us </p>
                    </div>
                    <div className="price_card_content">
                      <div className="price_card_content_div">
                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Distribute photos for free</p>
                            <p>allow free photo access to guests</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Unlimited Guests</p>
                            <p>no limit on guests or selfies</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Unlimited Events</p>
                            <p>create multiple (unlimited) events</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Branding</p>
                            <p>
                              promote your brand to guests and their friends
                            </p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Unlimited Guests</p>
                            <p>no limit on guests or selfies</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>White Label</p>
                            <p>custom URLs and white labeling in your brand</p>
                          </div>
                        </div>

                        <div className="price_card_content_card">
                          <div className="price_card_content_card_left">
                            <img src={DoneIcon} alt="img" />
                          </div>
                          <div className="price_card_content_card_right">
                            <p>Account Manager</p>
                            <p>Dedicated account manager for quick support</p>
                          </div>
                        </div>
                      </div>
                      <button className="price_card_btn">Contact Us</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="price_footer_div">
              <Footer />
            </div>
          </div>
          <div className="price_overlay"></div>
        </div>
      </div>
    </>
  );
};

export default Price;
