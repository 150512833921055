import React, { useEffect, useState } from "react";
import "./orgInfo.css";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import MobileImg from "../../../assets/eventSetting/mobile.png";
import { TOKEN, dEV_API_URL } from "../../../API";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const OrgInfo = () => {
  const navigate = useNavigate();

  const [coverImg, setCoverImg] = useState(null);
  const [postStatus, setPoststatus] = useState(false);

  const [flag, setFlag] = useState(false);
  const [respData, setRespData] = useState([]);
  const [isCoverImgChange, setIsCoverImgChange] = useState(false);

  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");
  

  let validationSchema = Yup.object({
    brand_name: Yup.string()
      .min(3, "Brand name must be atleast 3 characters")
      .max(50, "Brand name must be at most 50 characters")
      .required("Brand name is required"),
    subtitle: Yup.string()
      .min(3, "Subtitle must be atleast 3 characters")
      .max(100, "Subtitle must be at most 100 characters")
      .required("Subtitle is required"),
  });

  useEffect(() => {
    // console.log(id);
    getOrgDetail(id);
  }, [id]);

  const getOrgDetail = (id) => {
    // setPoststatus(true);

    axios({
      method: "GET",
      url: `${dEV_API_URL}/branding/brand-landing-page/?event_id=${id}`,
      headers: {
        
        "content-type": "application/json",
        Authorization:
        TOKEN,
            },
    })
      .then((resp) => {
        console.log(resp.data);
        setRespData(resp.data);
        setCoverImg(resp.data.brand_logo);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
        // setPoststatus(false);
        setFlag(true);
      })
      .finally(() => {
        // setPoststatus(false);
      });
  };

  useEffect(() => {
    console.log(flag);
  }, [flag])

  const updateOrgDetail = (values) => {
    setPoststatus(true);

    var MethodType;

    if (respData.id) {
      MethodType = "PATCH";
    } else {
      MethodType = "POST";
    }

    const formData = new FormData();
    if (respData.id) {
      formData.append("landing_page_brand_id", respData.id);
    } else {
      formData.append("event_id", id);
    }
    if (isCoverImgChange) {
      formData.append("brand_logo", coverImg);
    }
    formData.append("brand_name", values.brand_name);
    formData.append("brand_tagline", values.subtitle);

    axios({
      method: MethodType,
      url: `${dEV_API_URL}/branding/brand-landing-page/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization:
        TOKEN,           },
      data: formData,
    })
      .then((res) => {
        console.log("res", res);

        toast.success('Organization Information Updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        // navigate("/events");
      })
      .catch((err) => {
        // console.log(err);
        toast.warning('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedTypes.includes(file.type)) {
      return true;
    } else {
      alert(`Invalid file type. Please select a PNG, JPG, or JPEG image.`);
      return false;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      setCoverImg(file);
    }
  };

  const removeImg = () => {
    setCoverImg(null);
  };

  return (
    <>
      <div className="org_info_main_container">
        <div className="org_info_header_div">
          <p>Configure URL Branding</p>
          <p>Please upload branding if you want to add branding on photos</p>
        </div>

        {flag && 
        <Formik
          initialValues={{
            brand_name: flag ? respData.brand_name : "",
            subtitle: flag ? respData.brand_tagline : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            updateOrgDetail(values);
          }}
        >
          {({ values, isValid, setFieldValue, errors, touched, resetForm }) => (
            <Form>
              <div className="org_info_basic_info_div">
                <div className="org_info_basic_info_left">
                  <div className="org_info_basic_info_field_box_div">
                    <label htmlFor="brand_name">Brand Name</label>
                    <Field
                      type="text"
                      id="brand_name"
                      name="brand_name"
                      className="org_info_basic_info_field_box"
                      placeholder="SNAPD"
                    />
                    <ErrorMessage
                      name="brand_name"
                      component="div"
                      className="org_info_basic_info_error"
                    />
                  </div>

                  <div className="org_info_basic_info_field_box_div">
                    <label htmlFor="subtitle">Subtitle / Tagline</label>
                    <Field
                      type="text"
                      id="subtitle"
                      name="subtitle"
                      className="org_info_basic_info_field_box"
                      placeholder="Enter your subtitle/tagline"
                    />
                    <ErrorMessage
                      name="subtitle"
                      component="div"
                      className="org_info_basic_info_error"
                    />
                  </div>

                  <>
                    <div
                      className="org_info_logo_upload_box"
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <p className="org_info_logo_custom_file_upload_label">
                        Upload Cover Image
                      </p>
                      {coverImg == null ? (
                        <>
                          <label
                            htmlFor="coverimg"
                            className="org_info_logo_custom_file_upload"
                          >
                            <Field
                              type="file"
                              id="coverimg"
                              name="coverimg"
                              className="org_info_logo_upload_input"
                              accept="image/png, image/jpeg, image/jpg"
                              style={{ display: "none" }}
                              value={values.file}
                              onChange={(e) => {
                                if (
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/jpg"
                                ) {
                                  setCoverImg(e.target.files[0]);
                                  setIsCoverImgChange(true);
                                }
                              }}
                            />
                            <p className="org_info_logo_upload_box_p1">
                              <span>Choose</span> file to upload
                            </p>
                            <p className="org_info_logo_upload_box_p2">or</p>
                            <p className="org_info_logo_upload_box_p3">
                              Drag & drop image to upload
                            </p>
                            <p className="upload_box_p4">
                              Recommended: 269x186 | jpeg, jpg, png | max. size:
                              2MB
                            </p>
                          </label>
                          <ErrorMessage
                            name="coverimg"
                            component="div"
                            className="org_info_logo_error"
                          />
                        </>
                      ) : (
                        <div className="org_info_photos_cover_img_div">
                          {/* <img
                            src={URL.createObjectURL(coverImg)}
                            alt="cover_image"
                          /> */}

                          {typeof coverImg === "string" ? (
                            <img src={coverImg} alt="coverImg" />
                          ) : (
                            <img
                              src={URL.createObjectURL(coverImg)}
                              alt="cover_image"
                            />
                          )}

                          <div className="org_info_photos_trash_div">
                            <div
                              onClick={removeImg}
                              className="org_info_photos_trash_inner_div"
                            >
                              <FaTrashAlt color="white" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>

                <div className="org_info_basic_info_right">
                  <img src={MobileImg} alt="mobile_pic" />
                </div>
              </div>

              <div className="org_info_form_btns">
                {postStatus ? (
                  <p className="org_info_form_submit_btn">
                    <span>
                    <ClipLoader
                    color="white"
                    size={24}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                    </span>
                  </p>
                ) : (
                  <>
                    <p className="org_info_form_cancel_btn" 
                        onClick={() => {
                          resetForm();
                          navigate(-1);
                        }}
                      >
                      <span>
                        Cancel
                      </span>
                    </p>
                    <button
                      className="org_info_form_submit_btn"
                      disabled={!isValid}
                      type="submit"
                    >
                      <span>Save</span>
                    </button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik> }
      </div> 
    </>
  );
};

export default OrgInfo;
