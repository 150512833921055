import React, { useState, useEffect } from "react";
import "./yourphotos.css";
import { Modal, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CircularProgress from "@mui/material/CircularProgress";
import { dEV_API_URL } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import CloseIcon from "../../assets/icons/close.svg";
import DownloadIcon from "../../assets/icons/download.svg";


// const applyWatermark = (imageSrc, logoPosition, logoUrl, watermarkUrl) => {
//   return new Promise((resolve) => {
//     const img = new Image();
//     img.crossOrigin = "anonymous";
//     img.src = imageSrc;

//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");

//       canvas.width = img.width;
//       canvas.height = img.height;

//       ctx.drawImage(img, 0, 0);

//       const drawLogo = () => {
//         if (!logoUrl) {
//           return resolve(canvas.toDataURL());
//         }

//         const logo = new Image();
//         logo.crossOrigin = "anonymous";
//         logo.src = logoUrl;

//         logo.onload = () => {
//           const logoWidth = 300;
//           const logoHeight = 100;
//           let logoX = 10;
//           let logoY = 10;

//           switch (logoPosition) {
//             case "top_left":
//               logoX = 10;
//               logoY = 10;
//               break;
//             case "top_right":
//               logoX = canvas.width - logoWidth - 10;
//               logoY = 10;
//               break;
//             case "bottom_left":
//               logoX = 10;
//               logoY = canvas.height - logoHeight - 10;
//               break;
//             case "bottom_right":
//               logoX = canvas.width - logoWidth - 10;
//               logoY = canvas.height - logoHeight - 10;
//               break;
//           }

//           ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);
//           resolve(canvas.toDataURL());
//         };

//         logo.onerror = () => resolve(canvas.toDataURL());
//       };

//       if (!watermarkUrl) {
//         return drawLogo();
//       }

//       const watermark = new Image();
//       watermark.crossOrigin = "anonymous";
//       watermark.src = watermarkUrl;

//       watermark.onload = () => {
//         const aspectRatio = watermark.width / watermark.height;
//         let watermarkWidth, watermarkHeight;

//         if (canvas.width / canvas.height > aspectRatio) {
//           watermarkWidth = canvas.width;
//           watermarkHeight = canvas.width / aspectRatio;
//         } else {
//           watermarkHeight = canvas.height;
//           watermarkWidth = canvas.height * aspectRatio;
//         }

//         const offsetX = (canvas.width - watermarkWidth) / 2;
//         const offsetY = (canvas.height - watermarkHeight) / 2;

//         ctx.save();
//         // ctx.globalAlpha = 0.5;
//         ctx.drawImage(
//           watermark,
//           offsetX,
//           offsetY,
//           watermarkWidth,
//           watermarkHeight
//         );
//         ctx.restore();

//         drawLogo();
//       };

//       watermark.onerror = () => drawLogo();
//     };

//     img.onerror = () => resolve(null);
//   });
// };

const YourPhotos = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [downloadImageUrls, setDownloadImageUrls] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [page, setPage] = useState(1);
  const [eventName, setEventName] = useState(null);
  const [photoCount, setPhotoCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  let queryParams = new URLSearchParams(window.location.search);
  let searchedFaceId = queryParams.get("searched_face_id");

  useEffect(() => {
    const fetchData = async () => {
      setLoadingImages(true);
      try {
        if (searchedFaceId != null) {
          const response = await fetch(
            `${dEV_API_URL}/recognition/fetch-searched_images/?searched_face_id=${searchedFaceId}&page=${page}&page_size=20`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          setEventName(data.event_name);
          setPhotoCount(data.count);
          if (page === 1) {
            setImageUrls(data.results);
          } else {
            setImageUrls((prevImageUrls) => [
              ...prevImageUrls,
              ...data.results,
            ]);
          }
          setLoadingImages(false);
          setHasMore(data.next !== null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingImages(false);
      }
    };

    fetchData();
  }, [searchedFaceId, page]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoadingImages(true);
  //     try {
  //       if (searchedFaceId != null) {
  //         const response = await fetch(
  //           `${dEV_API_URL}/recognition/fetch-searched_images/?searched_face_id=${searchedFaceId}&page=${page}&page_size=20`
  //         );
  //         if (!response.ok) {
  //           throw new Error("Failed to fetch data");
  //         }
  //         const data = await response.json();
  //         setEventName(data.event_name);
  //         setPhotoCount(data.count);
  //         const isBrandingImageEmpty =
  //           Object.keys(data.branding_image).length === 0;
  //         const isWatermarkImageEmpty =
  //           Object.keys(data.watermark).length === 0;
  //         const horizontalLogoUrl = isBrandingImageEmpty
  //           ? null
  //           : data.branding_image.horizontal_brand_image;
  //         const verticalLogoUrl = isBrandingImageEmpty
  //           ? null
  //           : data.branding_image.vertical_brand_image;
  //         const horizontalWatermarkUrl = isWatermarkImageEmpty
  //           ? null
  //           : data.watermark.horizontal_watermark;
  //         const verticalWatermarkUrl = isWatermarkImageEmpty
  //           ? null
  //           : data.watermark.vertical_watermark;
  //         const logoPosition = isBrandingImageEmpty
  //           ? null
  //           : data.branding_image.position;

  //         const watermarkPromises = data.results.map(async (image) => {
  //           return new Promise((resolve) => {
  //             const img = new Image();
  //             img.crossOrigin = "anonymous";
  //             img.src = image.image_url;

  //             img.onload = async () => {
  //               let logoUrl, watermarkUrl;
  //               if (img.width > img.height) {
  //                 logoUrl = horizontalLogoUrl;
  //                 watermarkUrl = horizontalWatermarkUrl;
  //               } else {
  //                 logoUrl = verticalLogoUrl;
  //                 watermarkUrl = verticalWatermarkUrl;
  //               }

  //               const watermarkedImage = await applyWatermark(
  //                 image.image_url,
  //                 logoPosition,
  //                 logoUrl,
  //                 watermarkUrl
  //               );

  //               resolve({ ...image, image_url: watermarkedImage });
  //             };
  //           });
  //         });

  //         const processedImages = await Promise.all(watermarkPromises);

  //         if (page === 1) {
  //           setImageUrls(processedImages);
  //           setDownloadImageUrls(data.results);
  //         } else {
  //           setImageUrls((prevImageUrls) => [
  //             ...prevImageUrls,
  //             ...processedImages,
  //           ]);
  //           setDownloadImageUrls((prevDownloadImageUrl) => [
  //             ...prevDownloadImageUrl,
  //             ...data.results,
  //           ]);
  //         }

  //         setLoadingImages(false);
  //         setHasMore(data.next !== null);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoadingImages(false);
  //     }
  //   };

  //   fetchData();
  // }, [searchedFaceId, page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const openImage = (index) => {
    setSelectedImageIndex(index);
    setOpenGalleryModal(true);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  const navigate = (direction) => {
    if (selectedImageIndex !== null) {
      const newIndex = selectedImageIndex + direction;
      if (newIndex >= 0 && newIndex < imageUrls.length) {
        setSelectedImageIndex(newIndex);
      }
    }
  };
  const downloadImage = async () => {
    try {
      setLoading(true);
      if (selectedImageIndex !== null) {
        const imageUrl = imageUrls[selectedImageIndex].image_url;
        const parts = imageUrl.split("/");
        const filenameWithQuery = parts[parts.length - 1];

        const filenameParts = filenameWithQuery.split("?");
        const filename = filenameParts[0];

        let imageName = filename;
        const response = await fetch(imageUrl, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  
  // const downloadImage = async () => {
  //   try {
  //     setLoading(true);
  //     if (selectedImageIndex !== null) {
  //       const imageUrl = imageUrls[selectedImageIndex].image_url;
  //       const downloadUrl = downloadImageUrls[selectedImageIndex].image_url;
  //       const parts = downloadUrl.split("/");
  //       const filenameWithQuery = parts[parts.length - 1];

  //       const filenameParts = filenameWithQuery.split("?");
  //       const filename = filenameParts[0];

  //       let imageName = filename;
  //       const response = await fetch(imageUrl, {
  //         headers: {
  //           "Cache-Control": "no-cache",
  //         },
  //       });
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = imageName;
  //       document.body.appendChild(link);
  //       link.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(link);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow key pressed
        navigate(-1);
      } else if (event.keyCode === 39) {
        // Right arrow key pressed
        navigate(1);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <>
      <div className="OuterPhotoContainer">
        <div className="innerPhotoContainer" id="scroll-screen">
          <div className="MainHeader">
            <div className="subHeader">
              {eventName}
              <div className="header">Your Photos {photoCount}</div>
            </div>
          </div>
          <InfiniteScroll
            dataLength={imageUrls.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />{" "}
              </div>
            }
            scrollableTarget="scroll-screen"
            endMessage={<p className="your_image_no_more">No more images.</p>}
          >
            <div className="imagesList">
              {imageUrls.map((image, index) => (
                <img
                  src={image.image_url}
                  alt={`Image_${index + 1}`}
                  key={index}
                  className="imageStyle"
                  onClick={() => openImage(index)}
                />
              ))}
            </div>
          </InfiniteScroll>

          {selectedImageIndex !== null && (
            <Modal open={openGalleryModal} className="imageModal">
              {imageUrls && imageUrls[selectedImageIndex] && (
                 <Box
                 className="imageModal_box"
                 sx={{
                   width: "100%",
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                   height: "100vh",
                   background: "#0c0715",
                 }}
               >
                <div className="your_photo_modal_inner_div">
                 
                <div className="your_photo_modal_buttons_div">
                    <div className="your_photo_modal_buttons_header">
                    {loading ? (
                        <CircularProgress size={24} /> 
                      ) : 
                      <img
                        onClick={downloadImage}
                        src={DownloadIcon}
                        alt="download"
                      />}

                      <img onClick={closeImage} src={CloseIcon} alt="close" />
                    </div>
                    <div>
                      <div className="your_photo_modal_buttons_arrows">
                        <div className="your_photo_modal_buttons_arrows_inner">
                          {selectedImageIndex > 0 && (
                            <div onClick={() => navigate(-1)}>
                              <ArrowBackIosNewIcon className="your_photo_modal_buttons_arrow_icons" />
                            </div>
                          )}
                        </div>
                        <div className="your_photo_modal_buttons_arrows_inner">
                          {selectedImageIndex < imageUrls.length - 1 && (
                            <div onClick={() => navigate(1)}>
                              <ArrowForwardIosIcon className="your_photo_modal_buttons_arrow_icons" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>


                  <img
                    src={imageUrls[selectedImageIndex].image_url}
                    alt={`Image_${selectedImageIndex}`}
                    className="modal-image"
                  />
                </div>
                </Box>
              )}
            </Modal>
          )}
        </div>
      </div>
      <div className="downloadAllButtonContainer">
        <Link
          className="downloadAllButton"
          to={`/download?search_id=${searchedFaceId}&eventName=${eventName}`}
        >
          Download all images
        </Link>
      </div>
    </>
  );
};

export default YourPhotos;
